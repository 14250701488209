import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="psion-uncarnate"></a><h3>PSION UNCARNATE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80457.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d4.
    <a id="psion-uncarnate-requirements"></a><h6>Requirements</h6>
To qualify to become a psion uncarnate, a character must fulfill all
the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="psionicSkills.html#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge
(psionics)</a> 8 ranks, <a href="psionicSkills.html#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a> 8 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feat:</span> <a href="psionicFeats.html#psionic-body" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic
Body</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Able to
manifest 3rd-level powers.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Must have had
some instruction by another psion uncarnate.</p>
    <h6>Class Skills</h6>
The psion uncarnate&#8217;s class skills are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis</a> (Wis), <a href="skillsAll.html#bluff" style={{
      "color": "rgb(87, 158, 182)"
    }}>Bluff</a>
(Cha), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(any) (Int), <a href="skillsAll.html#disguise" style={{
      "color": "rgb(87, 158, 182)"
    }}>Disguise</a> (Cha), <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a>
(the
planes) (Int), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a> (Int), <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a>
(Int), and <a href="skillsAll.html#sense-motive" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sense
Motive</a> (Wis).
    <span style={{
      "fontWeight": "bold"
    }}>Skill Points at Each Level:</span>
2 + Int modifier.
    <a id="table-the-psion-uncarnate"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Psion Uncarnate</span>&nbsp;&nbsp;&nbsp;
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "30%"
          }}>Special
          </th>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Powers
Known</td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Incorporeal touch 1d6, uncarnate armor
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>Shed body 1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>Assume equipment
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Assume likeness
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Incorporeal touch 2d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Shed body 2/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Telekinetic force
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>Uncarnate bridge
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>Incorporeal touch 3d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+5</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">Uncarnate
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="psion-uncarnate-class-features"></a><h6>Class Features</h6>
All the following are class features of the psion uncarnate prestige
class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Psion uncarnates gain no proficiency with any weapon or armor.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> At every
level indicated on Table: The Psion Uncarnate, a psion uncarnate gains
additional power points per day and access to new powers as if he had
also gained a level in whatever manifesting class he belonged to before
he added the prestige class. He does not, however, gain any other
benefit a character of that class would have gained (bonus feats,
metapsionic or item creation feats, psicrystal special abilities, and
so on). This essentially means that he adds the level of psion
uncarnate to the level of whatever manifesting class the character has,
then determines power points per day, powers known, and manifester
level accordingly.
If a character had more than one manifesting class before he became a
psion uncarnate, he must decide to which class he adds the new level of
psion uncarnate for the purpose of determining power points per day,
powers known, and manifester level.</p>
    <a id="psion-uncarnate-incorporeal-touch"></a><p><span style={{
        "fontWeight": "bold"
      }}>Incorporeal Touch (Su):</span>
Beginning at 1st level, a psion uncarnate can make up to three melee
touch attacks per day that each deal 1d6 points of damage if they hit.
The character&#8217;s Strength modifier is not applied to this attack, but it
is effective against <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures (and
against corporeal
creatures while the psion uncarnate is incorporeal) The character&#8217;s
hand and arm seem to become slightly translucent when he makes these
attacks. A miss still counts as a use of the ability.
While uncarnate (see below), a psion uncarnate can make melee touch
attacks at will that do not count against his uses of this ability.
For every four levels higher than 1st the psion uncarnate attains, the
damage on these attacks increases by 1d6 points.</p>
    <a id="psion-uncarnate-uncarnate-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Uncarnate Armor (Su):</span>
A psion uncarnate wearing armor (or using <a href="psionicPowersGtoP.html#inertial-armor" style={{
        "color": "rgb(87, 158, 182)"
      }}>inertial armor </a>or a similar
effect) gets his armor bonus to AC even when he becomes <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a>
(see Shed Body, below). However, unlike other incorporeal creatures, a
psion uncarnate does not gain a deflection bonus to Armor Class from
his Charisma modifier. This ability works even if the armor being worn
becomes incorporeal (such as through the use of the assume equipment
ability described below).</p>
    <a id="psion-uncarnate-shed-body"></a><p><span style={{
        "fontWeight": "bold"
      }}>Shed Body (Su):</span>
Starting at 2nd level, a psion uncarnate can become <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> (or
&#8220;uncarnate&#8221;) once per day as a standard action. The character can
remain uncarnate for up to 1 minute. During this time, the character&#8217;s
body fades into an immaterial form that retains the character&#8217;s basic
likeness. While uncarnate, the character gains the incorporeal subtype
(see below). He gains a fly speed equal to his land speed (perfect
maneuverability). His material armor remains in place and continues to
provide its armor bonus to AC (see Uncarnate Armor, above). His
material weapons also remain corporeal. Losing his physical form allows
the character to more easily access his mental abilities, and he gains
a +1 bonus on all save DCs for powers he manifests while uncarnate.
He can use equipment normally, deriving benefits from items that
enhance his capabilities; however, all his equipment remains material
even when the character is uncarnate (but see the assume equipment
ability, described below).
Often, a psion uncarnate appears almost like a ghost wearing items of
the material world. This doesn&#8217;t make his equipment more susceptible to
attack (the normal rules for attended objects apply), but it does make
it impossible for the character to enter or pass through solid objects
while wearing solid equipment. If he drops his material equipment, he
can pass through solid objects at will as described below.
At 6th level and higher, a psion uncarnate can shed his body twice per
day for up to 1 minute each time. </p>
    <a id="psion-uncarnate-incorporeal-subtype"></a><p><span style={{
        "fontStyle": "italic"
      }}>Incorporeal Subtype:</span>
An incorporeal psion uncarnate has no physical body. He can be harmed
only by other incorporeal creatures, magic weapons or creatures that
strike as magic weapons, and spells, spell-like abilities, or
supernatural abilities. He is immune to all nonmagical attack forms.
Even when hit by spells or magic weapons, he has a 50% chance to ignore
any damage from a corporeal source (except for positive energy,
negative energy, force effects, or attacks made with ghost touch
weapons).
An incorporeal psion uncarnate has no natural armor bonus - and, unlike
other incorporeal creatures, does not gain a deflection bonus from his
Charisma modifier. An incorporeal psion uncarnate can enter or pass
through solid objects (subject to the restrictions described in the
shed body and assume equipment abilities), but must remain adjacent to
the object&#8217;s exterior, and so cannot pass entirely through an object
whose space is larger than his own. He can sense the presence of
creatures or objects within a square adjacent to his current location,
but enemies have total concealment (50% miss chance) from an
incorporeal psion uncarnate that is inside an object. To see farther
from the object he is in and attack normally, the incorporeal psion
uncarnate must emerge. An incorporeal psion uncarnate inside an object
has total cover, but when he attacks a creature outside the object he
only has cover, so a creature outside with a readied action could
strike at him as he attacks. An incorporeal psion uncarnate cannot pass
through a force effect.
An incorporeal psion uncarnate&#8217;s attacks pass through (ignore) natural
armor, armor, and shields, although deflection bonuses and force
effects work normally against him. He can pass through and operate in
water as easily as he does in air. An incorporeal psion uncarnate
cannot fall or take falling damage. He cannot make <a href="specialAttacks.html#trip" style={{
        "color": "rgb(87, 158, 182)"
      }}>trip</a>
or <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
attacks, nor can he be tripped or grappled. In fact, he cannot take any
physical action that would move or manipulate an opponent or its
equipment, nor is he subject to such actions.
Incorporeal creatures have no weight and do not set off traps that are
triggered by weight. An incorporeal creature moves silently and cannot
be heard with <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> checks if it doesn&#8217;t wish
to be. It has no
Strength score, so its Dexterity modifier applies to both its melee
attack rolls and its ranged attack rolls. Nonvisual senses, such as <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a>
and <a href="monsterTypes.html#blindsight" style={{
        "color": "rgb(87, 158, 182)"
      }}>blindsight</a>,
are either ineffective or only partly effective
with regard to incorporeal creatures. Incorporeal creatures have an
innate sense of direction and can move at full speed even when they
cannot see.</p>
    <a id="psion-uncarnate-assume-equipment"></a><p><span style={{
        "fontWeight": "bold"
      }}>Assume Equipment (Su):</span>
Beginning at 3rd level, a psion uncarnate can designate a number of
pieces of his worn equipment (including armor and weapons) equal to his
psion uncarnate level to become incorporeal when he uses his shed body
ability. This has no effect on the equipment&#8217;s function, but now when
the psion uncarnate is incorporeal, he can enter or pass through solid
objects while wearing nothing other than the designated equipment. Once
designated, the equipment automatically changes to incorporeal when the
character sheds his body, and it returns to corporeality when the
character does. The character can change his designations as he desires.</p>
    <a id="psion-uncarnate-assume-likeness"></a><p><span style={{
        "fontWeight": "bold"
      }}>Assume Likeness (Su):</span>
At 4th level and higher, while incorporeal, a psion uncarnate can
assume the likeness of any Small, Medium, or Large creature as a
standard action that does not provoke attacks of opportunity. The
character&#8217;s abilities do not change, but he appears to be the creature
that he assumes the likeness of, allowing him the ability to
effectively disguise himself and bluff those who might wonder at his
true nature. Each physical interaction with a creature requires a
successful <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a> check (opposed by the
creature&#8217;s <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive </a>check)
to convince the creature of the psion uncarnate&#8217;s new appearance. The
psion uncarnate must not do anything to give away his true
(incorporeal) nature in order for the bluff to be successful; for
instance, if he accepts an item from another creature only to have it
fall through his immaterial hands, the Bluff check automatically fails.
However, a Bluff check would be allowed if the psion uncarnate uses his
telekinetic force ability (see below) to hold the received item.
When using his assume likeness ability, a psion uncarnate has an
additional +10 circumstance bonus on <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> checks. If he can read
an
opponent&#8217;s mind, he gets a further +4 circumstance bonus on Bluff and
Disguise checks.</p>
    <a id="psion-uncarnate-telekinetic-force"></a><p><span style={{
        "fontWeight": "bold"
      }}>Telekinetic Force (Su):</span>
Beginning at 7th level, while incorporeal, a psion uncarnate can use a <a href="psionicPowersQtoW.html#telekinetic-force" style={{
        "color": "rgb(87, 158, 182)"
      }}>telekinetic force</a> effect as a
standard action that does not provoke
attacks of opportunity. The save DC is equal to 14 + the psion
uncarnate&#8217;s key ability modifier (either Int, Wis, or Cha). The
character&#8217;s manifester level is the manifester level of the effect.
Even while corporeal, a psion uncarnate can use this ability, but only
three times per day (uses while he is uncarnate do not count against
this use limit).</p>
    <a id="psion-uncarnate-uncarnate-bridge"></a><p><span style={{
        "fontWeight": "bold"
      }}>Uncarnate Bridge (Su):</span>
At 8th level, as a creature of almost pure mind, a psion uncarnate
becomes more closely attuned to the minds of other creatures. He gains
the ability to transport himself via the minds of living creatures.
Once per day as a standard action while incorporeal, he can seamlessly
enter any living creature with an Intelligence score and pass to
another living creature with an Intelligence score that is within line
of sight of the first creature.
The psion uncarnate must be in a space adjacent to the entry creature
before transporting, and he appears in a space adjacent to the
destination creature after transporting. The entry and destination
creatures need not be familiar to the character. A psion uncarnate
cannot use himself as the entry or destination creature. Neither
creature need be a willing participant.
When exiting the destination creature, the psion uncarnate chooses an
adjacent square in which to appear. Entering and leaving a creature is
painless, unless the psion uncarnate wishes otherwise (see below). In
most cases, though, the destination creature finds being the endpoint
of a mental bridge surprising and quite unsettling.
If he desires, a psion uncarnate can destructively exit the destination
creature. If the creature fails a Will save (DC 15 + psion uncarnate&#8217;s
key ability modifier), the exiting psion uncarnate tunes his mental
form to destructively interfere with the target&#8217;s mind. He bursts forth
explosively from the creature&#8217;s body, dealing it 10d6 points of damage.</p>
    <a id="psion-uncarnate-uncarnate"></a><p><span style={{
        "fontWeight": "bold"
      }}>Uncarnate (Ex):</span> At
10th level, a psion uncarnate becomes a being of pure psionic
consciousness. This ability is similar to shed body, except the
character is permanently incorporeal (and gains that subtype). If the
character desires, he can become corporeal once per day for up to 1
minute, but he spends the rest of his time as an entity of mind
untethered by the physical world.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      